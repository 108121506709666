<template>
	<div class="wrapper">
		<alert :show="message" :color="color">
			{{ message }}
		</alert>
		<div class="card card-container">
			<div class="voltar">
				<button type="button" class="button">
					<router-link to="/login" class="nav-link">
						<img src="../assets/icon/arrow-left.svg" /> Voltar
					</router-link>
				</button>
			</div>
			<div class="container-recover">
				<img id="logo-img" src="../assets/logo.svg" class="logo-img-card" />
				<img id="solucao-img" src="../assets/solucao_aliare.svg" class="solucao-img-card" />
				<p>Recuperar Senha</p>
				<div class="info">
					<img class="icon-img" src="../assets/icon/info.svg" alt="" />
					<div>
						<span class="bold">
							Digite seu e-mail cadastrado no Aliare Integra.
						</span>
						<span>
							Através dele lhe enviaremos as instruções para redefinição da
							senha.
						</span>
					</div>
				</div>
				<form :validation-schema="schema">
					<div class="label-float form-group labelError">
					<b-form-group>
						<b-form-input
							data-testid="input-username"
							autofocus
							id="username"
							name="username"
							type="email"
							placeholder=""
							autocomplete="off"
							v-model.trim="$v.form.email.$model"
							:value.trim="$v.form.email.$model"
							@change="validateEmail"
							@focusin="validateEmail"
							:state="getValidation('email')"
						>
						</b-form-input>
						<label for="username">{{ $locale.baseText('login.username') }}</label>
							<b-form-invalid-feedback id="username-feedback">
								{{ $locale.baseText("login.alertEmailInvalid") }}
							</b-form-invalid-feedback>
					</b-form-group>
					</div>

					<div class="form-group form-group-btn">
						<div class="btn btn-primary btn-block" :disabled="true" @click="handleLogin">
							<span>{{ $locale.baseText("login.sendEmail") }}</span>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import * as yup from 'yup';
import { mapActions } from 'vuex';
import Alert from '@/components/Alert.vue';

export default {
	components: { Alert },
	data() {
		const schema = yup.object().shape({
			username: yup.string().required(this.$locale.baseText('validations.requiredUsername')),
		});

		return {
			message: '',
			inputError: false,
			form: {
				email: '',
			},
			schema,
			color: '',
		};
	},
	validations: {
		form: {
			email: {
				required,
				email,
			},
		},
	},

	methods: {
		...mapActions('auth', ['recoverPassword']),

		handleLogin() {
			const user = this.$v.form.email.$model;

			this.recoverPassword(user)
				.then(() => {
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('login.emailSucces'),
					})
				})
				.catch((error) => {
						this.$store.commit('activeAlert', {
						message: this.$locale.baseText('login.errorEmailIcorrect'),
						status: 'error',
					});
				});
		},

		handleLoginError(error) {
			this.message = error.response?.data?.notifications[0] || error.message || error.toString();
			this.showAlert();
			this.color = 'error';
			setTimeout(() => {
				this.message = '';
			}, 5000);
		},

		handleSucess(result) {
			if (result.status !== 200) {
				return;
			}
			this.message = "E-mail enviado com sucesso. Dentro de instantes você receberá um e-mail com as instruções necessárias para que possa redefinir a senha.";
			this.showAlert();
			this.color = 'sucess';

			setTimeout(() => {
				this.message = '';
			}, 5000);
		},

		validateEmail() {
			const email = document.getElementById('username').value;
			const re = /\S+@\S+\.\S+/;
			if (re.test(email)) {
				document.getElementsByClassName('btn btn-primary btn-block')[0].disabled = false;
				document.getElementsByClassName('btn btn-primary btn-block')[0].style.pointerEvents =
					'auto';
			} else {
				document.getElementsByClassName('btn btn-primary btn-block')[0].disabled = true;
				document.getElementsByClassName('btn btn-primary btn-block')[0].style.pointerEvents =
					'none';
			}
			return re.test(email);
		},

		getValidation(field) {
			if (this.$v.form.$dirty === false) {
				return null;
			}

			return !this.$v.form[field].$error;
		},

		showAlert() {
			this.dismissCountDown = this.dismissSecs;
		},

		getValidation(field) {
			if (this.$v.form.$dirty === false) {
				return null;
			}

			return !this.$v.form[field].$error;
		},
	},
};
</script>

<style scoped lang="scss">
.wrapper {
	background-image: url('../assets/imagens/img-login.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	display: flex;
	height: 100vh;
	align-items: center;
	justify-content: flex-end;
}

:deep .form-control:focus {
	background-color: #223049;
	color: #ffffff;
}

:deep {
	.was-validated .form-control:invalid, .form-control.is-invalid {
		border-color: #dc3545;
		border: 1px solid #dc3545 !important;
		background-image: url('../assets/icon/alert.svg');

	}

	.was-validated .form-control:valid, input#username.form-control.is-invalid + label {
			color:#dc3545;
	}
}

label {
	display: block;
	margin-top: 18px;
}

.btn-block {
	width: 349px;
	height: 40px;
}

.button {
	background-color: transparent;
	background-repeat: no-repeat;
	border: none;
	cursor: pointer;
	overflow: hidden;
	outline: none;
	padding-top: 15px;
	padding-left: 31px;
}

.btn-primary {
	background-color: #00e0ff;
	border-color: #00e0ff;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	text-align: center;
	color: #070707;
}

.nav-link {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #00e0ff;
	padding: 0;
}

.container-recover {
	padding: 32px 32px;
	position: relative;
	display: flex;
	flex-direction: column;
}

.bold {
	font-weight: bold;
}

.info {
	color: #ffffff;
	background-color: #1a2757;
	height: 82px;
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}

input {
	background-color: #223049;
	border: 1px solid #00e0ff !important;
	color: #ffffff;
	width: 349px;
	height: 40px;
}

input[type="checkbox"] {
	background-color: #223049;
	border: 1px solid #00e0ff !important;
	color: #ffffff;
	width: 18px;
}

.input_1_ {
	border: 0px !important;
	border-radius: 0px !important;
	outline: none !important;
	min-width: 0px !important;
	padding: 0px !important;
	-webkit-appearance: none !important;
}

input[type="checkbox"] {
	display: none;
}

input[type="checkbox"] {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 25px;
	width: 25px;
	display: inline-block;
}

input#input_1_0 {
	position: absolute;
	right: 3px;
	margin-top: 7px;
	height: 25px;
	width: 36px;
	border: 0px !important;
	background-size: auto !important;
	background-image: url("../assets/icon/eye.svg");
}

::input:-internal-autofill-selected {
	background-color: #223049 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #223049 inset;
	-webkit-text-fill-color: white !important;
}

p {
	color: #ffffff;
	justify-content: center;
	display: flex;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	text-align: center;
	padding-top: 64px;
	padding-bottom: 24px;
	margin-bottom: 0px;
}

.acesso {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #ffffff;
	padding-left: 6px;
	margin-top: 5px;
}

.esqueceu {
	color: #00ea00 !important;
	padding-left: 77px !important;
}

.voltar {
	height: 46px;
	background-color: #233149;
}

.card-container.card {
	width: 413px;
	height: 544px;
	margin-right: 32px;
	margin-left: 32px;
}

.card {
	background-color: #1c2638;
	border-radius: 5px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.icon-img {
	padding-left: 15px;
	padding-right: 16px;
}

.logo-img-card {
	width: 143.49px;
	height: 40px;
	margin: 0 auto 19px;
	display: block;
}

.solucao-img-card {
	width: 120.48px;
	height: 16px;
	margin: 0 auto 0px;
	display: block;
}

.label-float {
	position: relative;
	padding-top: 24px;
	display: flex;
}

.label-float input {
	border: 1px solid lightgrey;
	border-radius: 5px;
	outline: none;
	min-width: 250px;
	padding: 15px 20px;
	font-size: 16px;
	transition: all 0.1s linear;
	-webkit-transition: all 0.1s linear;
	-moz-transition: all 0.1s linear;
	-webkit-appearance: none;
}

.label-float input:focus {
	border: 2px solid #00e0ff;
}

.label-float input::placeholder {
	color: transparent;
}

.label-float label {
	pointer-events: none;
	position: absolute;
	top: calc(50% - 22px);
	left: 10px;
	transition: all 0.1s linear;
	-webkit-transition: all 0.1s linear;
	-moz-transition: all 0.1s linear;
	color: #ffffff;
	padding: 5px;
	box-sizing: border-box;
}

.label-float input:focus+label,
.label-float input:not(:placeholder-shown)+label {
	font-size: 13px;
	top: -12px;
	color: #00e0ff;
	background: linear-gradient(to bottom,
			#1c2638 0%,
			#1c2638 50%,
			#223049 51%,
			#223049 100%);
}

.form-group-btn {
	padding-top: 27px !important;
	display: flex;
}

.container1 {
	display: block;
	position: relative;
	padding-left: 28px;
	margin-bottom: 12px;
	font-size: 14px;
	cursor: pointer;
	font-style: normal;
	font-weight: normal;
	color: #ffffff;
}

/* Esconder o checkbox padrão */
.container1 input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Criar o checkbox customizado */
.checkmark {
	position: absolute;
	top: 2px;
	left: 0;
	height: 18px;
	width: 18px;
	border: 1px solid #00e0ff !important;
	border-radius: 2px;
}

/* Adicionar uma cor de fundo cinza ao passar o mouse */
.container1:hover input~.checkmark {
	background-color: #ccc;
}

/* Quando o checkbox é clicado, adicionar um fundo rosa */
.container1 input:checked~.checkmark {
	background-color: #00e0ff;
}

/* Criar o ícone de check, escondido quando não estiver selecionado */
.container1 .checkmark:after {
	content: "";
	position: absolute;
	display: none;
	left: 6px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* Exibir o ícone quando selecionado */
.container1 input:checked~.checkmark:after {
	display: block;
}
</style>
